const api = {}

const BASE_URL = "https://mini-bo-api-23hjk3yt7823yhk.cleanbox.co.il" // mini bo prod
//const BASE_URL = "https://mini-bo-api-test.cleanbox.co.il" // mini bo test
//const BASE_URL = "https://localhost:5003" //local

//LOGIN
api.signIn = `${BASE_URL}/api/Login`
api.signInBody = (mobile, pwd) => {
  return {
    mobilePhone: mobile,
    password: pwd,
  }
}

//USERS
api.updateUserPassword = () => `${BASE_URL}/api/users/update_password`
api.getUsersByRole = `${BASE_URL}/api/users/get_users_by_role`
api.deleteUser = (userId) => `${BASE_URL}/api/users/delete_user/${userId}`
api.resetPassword = (userId) => `${BASE_URL}/api/users/reset_password/${userId}`
api.createUser = `${BASE_URL}/api/users/create_user`
api.createNewUserBody = ({
  firstName,
  lastName,
  mobilePhone,
  stationId,
  role,
}) => {
  return {
    firstName,
    lastName,
    mobilePhone,
    stationId,
    role,
  }
}
//BOUser
api.addBOUser = (userId, role, accountType) =>
  `${BASE_URL}/api/users/add_bo/${userId}/${role}/${accountType}`
api.removeBOUser = (userId, role, accountType) =>
  `${BASE_URL}/api/users/remove_bo/${userId}/${role}/${accountType}`
api.resetBOPassword = (boUserId) =>
  `${BASE_URL}/api/users/reset_bo_password/${boUserId}`

//ORDERS
api.fetchOpenOrders = `${BASE_URL}/api/orders/GetOrders`
api.searchOrders = `${BASE_URL}/api/orders/search_orders`
//api.searchOrder = `${BASE_URL}/api/orders/SearchOrder`
api.getOrderTypes = `${BASE_URL}/api/orders/get_types`
api.getOrderTypesByRole = `${BASE_URL}/api/orders/get_types_by_role`

api.getOrderDetails = (orderId) => `${BASE_URL}/api/orders/get_order/${orderId}`
api.updateOrderExtensionDate = (orderId, inLockerExtentionDate) =>
  `${BASE_URL}/api/orders/updateOrderStatusAndExtentionDate/${orderId}/${inLockerExtentionDate}`

//CREATE NEW  ORDER
api.createOrder = `${BASE_URL}/api/orders/importOrdersWithStatus`

//UPDATE ORDER
api.updateOrder = `${BASE_URL}/api/orders/importOrdersWithStatus`

//ORDER STATUSES
api.getOrderStatuses = `${BASE_URL}/api/orders/get_status_types`

//UPDATE MESSAGE
api.updateMessage = (stationId) =>
  `${BASE_URL}/api/stations/update_station_message/${stationId}`

//GET STATIONS DECATHLON
api.getStationsDecathlon = `${BASE_URL}/api/stations/get_stations`

//GET LOCKER STATUSES
api.getLockersStatusesByStationId = (stationId) =>
  `${BASE_URL}/api/stations/lockers_status/${stationId}`

//GET LOCKERS LAYOUT
api.getLockersLayoutByStationId = (stationId) =>
  `${BASE_URL}/api/stations/get_layout/${stationId}`

//GET STATIONS
api.getStationsByRole = `${BASE_URL}/api/stations/get_stations_by_role`

//Reports
api.getPackageDebitReport = (month, year) =>
  `${BASE_URL}/api/DeliveryBilling/calcDeliveryBilling/${month}/${year}`

api.getDeliveryDetailsFromIsraelPost = (packageNumber) =>
  `${BASE_URL}/api/orders/get_israel_post_delivery/${packageNumber}`

api.getExternalNumberByPackage = (orderNumber, orderType) =>
  `${BASE_URL}/api/orders/getExternalNumByPackageAndType/${orderNumber}/${orderType}`

api.updateOrderStation = (orderId, stationNumber) =>
  `${BASE_URL}/api/orders/updateOrderStation/${orderId}/${stationNumber}`

api.getFailedSMS = () => `${BASE_URL}/api/notifications/GetNotificationsErrors`
export default api

api.getDownStations = `${BASE_URL}/api/Stations/getDownStations`

api.getLockersSelectOptions = `${BASE_URL}/api/Stations/get_lockers_map`
api.relocateOrder = `${BASE_URL}/api/orders/relocate_order`

api.importOrdersFromCSV = `${BASE_URL}/api/orders/importOrdersFromCSV`

api.sendInLockerSMS = (orderId) =>
  `${BASE_URL}/api/orders/sendInLockerSMS/${orderId}`

api.onTransferOrder = (orderId, stationId) =>
  `${BASE_URL}/api/orders/transferOrder/${orderId}/${stationId}`
